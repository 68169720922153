import { Formik } from "formik";
import moment from "moment";
import * as momenttz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { validateDate } from "../../../utilities/formatDate";
import { get, put } from "../../../utilities/request";
import { useAppState } from "../../App/AppProvider";
import { DateField } from "../../Forms/Field/Date";
import { Select } from "../../Forms/Field/Select";
import { Text } from "../../Forms/Field/Text";
import { Card } from "../Card/Card";
import { Loading } from "../Loading/Loading";
import useToast from "../../../utilities/useToast";
import { useAuth } from "../../../utilities/useAuth";

const timezones = momenttz.tz.names();

const isAccountInvalid = (values: any) => {
  if (values.AccountID === undefined) {
    return true;
  }
  if (values.createUser) {
    if (values.UserFirstName === undefined || values.UserFirstName === "") {
      return true;
    }
    if (values.UserLastName === undefined || values.UserLastName === "") {
      return true;
    }
    if (values.UserEmail === undefined || values.UserEmail === "") {
      return true;
    }
  } else if (values.UserID === undefined) {
    return true;
  }
  if (values.AccountName === undefined) {
    return true;
  }

  if (
    (values.ActionStartTs === undefined ||
      values.ActionTimezone === undefined) &&
    values.IncludedContent
  ) {
    return true;
  }
  return false;
};
export const AccountDuplicator = () => {
  const { auth } = useAppState();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const [pageStatus, setPageStatus] = useState<string>("Loading");
  const [userList, setUserList] = useState<any>(undefined);
  const [accountsList, setAccountsList] = useState<any>([]);


  useEffect(() => {
    const fetchRequirement = async () => {
      setPageStatus("Loading");
      const userData = await get("settings/allusers", getToken);
      const getAccounts = await get("admin/accounts", getToken);

      const fullUserList = userData.data.users.map((user: any) => ({
        ...user,
        UserName: user.UserFirstName.concat(` ${user.UserLastName}`),
        UserNameEmail: user.UserFirstName.concat(
          ` ${user.UserLastName}`,
        ).concat(` (${user.UserEmail})`),
      }));

      const fullAccountList = getAccounts.data.map((account: any) => ({
        ...account,
        AccountFullName: account.AccountName.concat(` (${account.AccountID})`),
      }));
      setUserList(fullUserList);
      setPageStatus("Ready");
      setAccountsList(fullAccountList);
    };

    if (auth.isLoggedIn) {
      fetchRequirement();
    }
  }, [auth]);

  return (
    <>
      <Container>
        <Formik
          initialValues={{}}
          onSubmit={async (values: any, actions) => {
            if (isAccountInvalid(values)) {
              actions.setSubmitting(false);
            } else {
              setPageStatus("Submitting");
              const user: any = userList.find(
                (user: any) => user.UserID === values.UserID,
              );

              const duplicateObject = {
                baseAccount: values.AccountID,
                UserID: values.createUser ? undefined : user.UserID,
                UserFirstName: values.createUser
                  ? values.UserFirstName
                  : user.UserFirstName,
                UserLastName: values.createUser
                  ? values.UserLastName
                  : user.UserLastName,
                IncludeContent: !!values.IncludedContent,
                UserEmail: values.createUser
                  ? values.UserEmail
                  : user.UserEmail,
                AccountName: values.AccountName,
                createUser: values.createUser,
                UserRoles: [],
                AccountID: values.AccountID,
                ActionStartTs: values.IncludedContent
                  ? validateDate(values.ActionStartTs, values.ActionTimezone)
                  : undefined,
                ActionTimeZone: values.ActionTimezone,
              };

              try {
                const reply = await put(
                  "duplicateAccount",
                  duplicateObject,
                  getToken,
                );
                navigate("/caadmin?success=1");
                displayToast({
                  status: "success",
                  title: `Account Duplication Successful`,
                });
              } catch (e) {
                displayToast({
                  status: "error",
                  title: "Failed to duplicate account",
                });
              } finally {
                setPageStatus("Ready");
              }
            }
          }}
          render={(formikProps) =>
            pageStatus === "Ready" ? (
              <Card title="Account Duplicator" collapsable={false}>
                <Form onSubmit={formikProps.handleSubmit}>
                  <Form.Group as={Row}>
                    <Form.Label style={{ textAlign: "right" }} column sm="2">
                      Account to Duplicate
                    </Form.Label>

                    <Col>
                      <Select
                        name="AccountID"
                        optionsList={accountsList}
                        value={formikProps.values.AccountID}
                        readOnly={formikProps.values.readOnly}
                        onUpdate={(val: any) => {
                          formikProps.setFieldValue("AccountID", val.value);
                        }}
                        optionsListID="AccountID"
                        optionsListValue="AccountFullName"
                      />
                    </Col>
                  </Form.Group>
                  <Row>
                    <Form.Label style={{ textAlign: "right" }} column sm="2">
                      Create new User
                    </Form.Label>

                    <Col sm="6">
                      <Form.Check
                        id="createUser"
                        name=""
                        value={formikProps.values.createUser}
                        label=""
                        checked={formikProps.values.createUser}
                        onChange={(val: any) => {
                          formikProps.values.createUser
                            ? formikProps.setFieldValue("createUser", false)
                            : formikProps.setFieldValue("createUser", true);
                        }}
                        type="checkbox"
                        disabled={formikProps.values.readOnly}
                      />
                    </Col>
                  </Row>
                  {formikProps.values.createUser ? (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ textAlign: "right" }}
                          column
                          sm="2">
                          {" "}
                          Email{" "}
                        </Form.Label>

                        <Col sm="6">
                          <Text
                            value={formikProps.values.UserEmail}
                            name="UserEmail"
                            onUpdate={formikProps.handleChange}
                            readOnly={formikProps.values.readOnly}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ textAlign: "right" }}
                          column
                          sm="2">
                          First Name
                        </Form.Label>

                        <Col sm="6">
                          <Text
                            value={formikProps.values.UserFirstName}
                            name="UserFirstName"
                            onUpdate={formikProps.handleChange}
                            readOnly={formikProps.values.readOnly}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label
                          style={{ textAlign: "right" }}
                          column
                          sm="2">
                          Last Name
                        </Form.Label>

                        <Col sm="6">
                          <Text
                            value={formikProps.values.UserLastName}
                            name="UserLastName"
                            onUpdate={formikProps.handleChange}
                            readOnly={formikProps.values.readOnly}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <Row>
                      <Form.Label style={{ textAlign: "right" }} column sm="2">
                        Account Owner
                      </Form.Label>
                      <Col>
                        <Select
                          name="UserID"
                          optionsList={userList}
                          value={formikProps.values.UserID}
                          readOnly={formikProps.values.readOnly}
                          onUpdate={(val: any) => {
                            formikProps.setFieldValue("UserID", val.value);
                          }}
                          optionsListID="UserID"
                          optionsListValue="UserNameEmail"
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Form.Label style={{ textAlign: "right" }} column sm="2">
                      Include Account Content
                    </Form.Label>

                    <Col sm="6" style={{ marginTop: "10px" }}>
                      <Form.Check
                        id="IncludedContent"
                        name=""
                        value={formikProps.values.IncludedContent}
                        label=""
                        checked={formikProps.values.IncludedContent}
                        onChange={(val: any) => {
                          formikProps.values.IncludedContent
                            ? formikProps.setFieldValue(
                                "IncludedContent",
                                false,
                              )
                            : formikProps.setFieldValue(
                                "IncludedContent",
                                true,
                              );
                        }}
                        type="checkbox"
                        disabled={formikProps.values.readOnly}
                      />
                    </Col>
                  </Row>
                  {formikProps.values.IncludedContent ? (
                    <Form.Group as={Row}>
                      <Form.Label column sm="2">
                        Actions Start Date
                      </Form.Label>
                      <Col sm="2">
                        <DateField
                          name="ActionStartTs"
                          selected={formikProps.values.ActionStartTs}
                          onUpdate={((name: any, data: any) =>
                            formikProps.setFieldValue(name, data)).bind(
                            {},
                            "ActionStartTs",
                          )}
                          readOnly={formikProps.values.readOnly}
                          min={moment().toDate()}
                          authState={auth}
                          allDates={false}
                        />
                      </Col>
                      <Col sm="3">
                        <Select
                          onUpdate={(e: any) => {
                            formikProps.setValues({
                              ...formikProps.values,
                              ActionTimezone: e.value,
                            });
                          }}
                          name="ActionTimezone"
                          value={formikProps.values.ActionTimezone}
                          unselectedOption="Select a Value"
                          optionsList={timezones}
                          optionsChild={(listItem: any) => ({
                            value: listItem,
                            label: listItem,
                          })}
                          readOnly={formikProps.values.readOnly}
                          readOnlyValue={formikProps.values.ActionTimezone}
                        />
                      </Col>
                    </Form.Group>
                  ) : null}

                  <Form.Group as={Row}>
                    <Form.Label style={{ textAlign: "right" }} column sm="2">
                      Account Name
                    </Form.Label>

                    <Col sm="6">
                      <Text
                        value={formikProps.values.AccountName}
                        name="AccountName"
                        onUpdate={formikProps.handleChange}
                        readOnly={formikProps.values.readOnly}
                      />
                    </Col>
                  </Form.Group>

                  {pageStatus === "Ready" ? (
                    <Col>
                      <Button
                        type="submit"
                        variant="outline-dark"
                        onClick={() => {
                          const errors = [];
                          if (formikProps.values.AccountID === undefined) {
                            errors.push('"Account to Duplicate is required."');
                          }
                          if (formikProps.values.createUser) {
                            if (
                              formikProps.values.UserFirstName === undefined ||
                              formikProps.values.UserFirstName === ""
                            ) {
                              errors.push('"User First Name is required."');
                            }
                            if (
                              formikProps.values.UserLastName === undefined ||
                              formikProps.values.UserLastName === ""
                            ) {
                              errors.push('"User Last Name is required."');
                            }
                            if (
                              formikProps.values.UserEmail === undefined ||
                              formikProps.values.UserEmail === ""
                            ) {
                              errors.push('"User Email is required."');
                            }
                          } else if (formikProps.values.UserID === undefined) {
                            errors.push('"Account Owner is required."');
                          }
                          if (formikProps.values.AccountName === undefined) {
                            errors.push('"Account Name is required."');
                          }
                          if (
                            formikProps.values.IncludedContent &&
                            (formikProps.values.ActionStartTs === undefined ||
                              formikProps.values.ActionTimezone === undefined)
                          ) {
                            errors.push(
                              '"Action Start Date & Timezone is required."',
                            );
                          }
                          if (
                            (errors.length > 0 && errors[0] !== "") ||
                            (errors.length > 1 && errors[0] === "")
                          ) {
                            const message = errors.join("\n");
                            displayToast({
                              status: "error",
                              title: "Failed to duplicate account",
                              description: message,
                            });
                          }
                        }}>
                        {" "}
                        Submit
                      </Button>
                    </Col>
                  ) : (
                    <Loading />
                  )}
                </Form>
              </Card>
            ) : (
              <Loading />
            )
          }
        />
      </Container>
    </>
  );
};
