import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card as Card2,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Frown, Plus } from "react-feather";
import {
  useLocation,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import * as yup from "yup";
import { AppState, ScreenProps } from "../../App.d";
import { useAppState } from "../../components/App/AppProvider";
import * as LocalStorage from "../../components/App/localStorage";
import { CustomFieldList } from "../../components/Forms/CustomFields/CustomField";
import { DateField } from "../../components/Forms/Field/Date";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Question } from "../../components/Forms/Field/Question";
import { Text } from "../../components/Forms/Field/Text";
import { Card } from "../../components/UI/Card/Card";
import { DocumentGenerator } from "../../components/UI/DocumentGenerator/DocumentGenerator";
import { TemplateDocumentGenerator } from "../../components/UI/DocumentGenerator/TemplatedDocumentGenerator";
import { Header } from "../../components/UI/Header/Header";
import { History } from "../../components/UI/History/History";
import Icon from "../../components/UI/Icon";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import {
  objectStatusToVariant,
  Status,
} from "../../components/UI/Status/Status";
import { getCustomFieldType } from "../../constants/customFieldType";
import { checkStoredValues } from "../../constants/storedValuesCheck";
import { urlRegex } from "../../constants/urlRegex";
import * as ObjectStatus from "../../shared/constants/objectStatus";
import * as Permissions from "../../shared/constants/permission";
import * as QuestionType from "../../shared/constants/questionType";
import { User } from "../../shared/definitions/users";
import * as Access from "../../utilities/access";
import { formatDateTime, validateDate } from "../../utilities/formatDate";
import { useLegacyApi } from "../../utilities/request";
import { clearLocationState } from "../../utilities/routing";
import { getUrlSearchParam } from "../../utilities/url";
import useToast, { DisplayToastFn } from "../../utilities/useToast";
import {
  getTaskWindow,
  isTaskAvailable,
  TaskWindow,
} from "../../utilities/tasks";

type ScreensInstancePageStatus =
  | "New"
  | "Loading"
  | "Ready"
  | "Responding"
  | "Submitting"
  | "Error";

type ScreensInstanceState = {
  InstanceName: string;
  ActionTypeID: string;
  ActionTypeName: string;
  ActionID: string;
  ActionName: string;
  ActionIsActive: boolean;
  NextInstanceOccurrence: string;
  ParentID: string;
  ParentObjectTypeGroup: string;
  InstanceID: string;
  ModifiedTs: string;
  ModifiedUserName: string;
  ResponsibleUserID: string;
  InstanceResponsibleUserName: string;
  InstanceResponsibleUserID: string;
  InstanceResponsibleUsers: any[];
  InstanceWhenTs: any;
  InstanceWindowBefore: number;
  InstanceWindowAfter: number;
  InstanceOnceOff: number;
  ObjectTypeID: string;
  ObjectStatusID: string;
  ObjectStatusName: string;
  ObjectStatusDescription: string;
  CustomFields: any[];
  Questions: any[];
  Documents: any[];
  ActionDescription: any;
  ActionReadableID: any;
  ActionTimezone: string;
  Requirements: any[];
  RiskMatrix: any;
  ResponsibleUsers: User[];
  CustomFieldsForQuestion: any[];
  InstanceMinViableResponse: number;
  Deleting?: boolean;
  DraftResponse?: boolean;
  Duplicates: any[];
  InstanceResponses: any[];
  ParentIDs: any[];
  taskWindow: TaskWindow;
} | null;

type InstanceMatchParams = {
  instanceID: string;
};

const isNumeric = (num: number | string): boolean => !isNaN(Number(num));

type InstanceParams = "instanceID";

const validationSchema = yup.object().shape({
  InstanceWhenTs: yup.date().required("Due date is required."),
});
const getColName = (id: string, cf: any) => {
  let colName = "";
  cf.forEach((field: any) => {
    if (id === field.CustomFieldID) {
      colName = field.CustomFieldTypeColumnName;
    }
  });
  return colName;
};
const getCfTypeID = (id: string, cf: any) => {
  let ID = "";
  cf.forEach((field: any) => {
    if (id === field.CustomFieldID) {
      ID = field.CustomFieldTypeID;
    }
  });
  return ID;
};

const isCFID = (event: any, cf: any[], a: any) => {
  let check = false;
  if (event.target !== undefined) {
    const id = event.target.name.split("_")[0];
    cf.forEach((field: any) => {
      if (id === field.CustomFieldID) {
        check = true;
      }
    });
  } else if (a.fileList) {
    check = true;
  } else if (event.cfID && a !== null) {
    const id = event.cfID;
    cf.forEach((field: any) => {
      if (id === field.CustomFieldID) {
        check = true;
      }
    });
  } else if (a) {
    if (a.fileList) {
      check = true;
    }
  }
  return check;
};
const getBreadcrumbs = (
  itemName: string,
  parentID: string,
  parentlist: any[],
  action: any,
  baseLink: Object,
  display: string,
) => {
  if (parentID !== "") {
    const breadcrumb = [];
    let breadFlag = false;
    let nextParentID: string = parentID;

    if (display !== "actions") {
      if (action.isActive) {
        breadcrumb.push(action);
      } else {
        breadcrumb.push({ label: action.label });
      }
      const filterFunction = (item: any) => item.RequirementID === nextParentID;
      while (breadFlag === false) {
        const item = parentlist.find(filterFunction);
        if (item) {
          breadcrumb.push({
            label: item.RequirementName,
            link: `/${item.ObjectTypeGroupName.toLowerCase()}/${
              item.RequirementID
            }${display}`,
          });
          if (item.ParentIDs[0] !== undefined) {
            nextParentID = item.ParentIDs[0];
          } else {
            breadFlag = true;
          }
        } else {
          breadFlag = true;
        }
      }
    }

    // Base link of type
    breadcrumb.push(baseLink);

    return breadcrumb.reverse();
  }
  return [];
};

const getControlList = (parentID: string, risks: any[]) => {
  let parent = risks.find((risk: any) => risk.RequirementID === parentID);
  if (!parent) {
    return [];
  }
  if (parent.ParentIDs.length > 0) {
    parent = risks.find((risk: any) => risk.ChildIDs.includes(parentID));
    const controlList = risks.filter((risk: any) =>
      risk.ParentIDs.includes(parent.RequirementID),
    );
    return controlList;
  }
  const controlList = risks.filter((risk: any) =>
    risk.ParentIDs.includes(parent.RequirementID),
  );
  return controlList;
};

const removeQuestion = (question: any, order: any, questions: any) => {
  const QS = questions.filter((q: any) => q.QuestionOrder !== order);
  let normalNumericIndex: any = 1;
  let newOrder: any = 0;
  const newQuestions = QS.map((q: any, index: any) => {
    if (isNumeric(q.InstanceQuestionID)) {
      if (q.InstanceQuestionID === normalNumericIndex) {
        normalNumericIndex += 1;
        newOrder += 1;
        return q;
      }
      const newQ = {
        ...q,
        InstanceQuestionID: normalNumericIndex,
        QuestionOrder: index,
      };
      newOrder += 1;
      normalNumericIndex += 1;
      return newQ;
    }
    normalNumericIndex = 1;
    newOrder += 1;
    return q;
  });
  return newQuestions;
};
const getParentMulti = (question: any, questions: any, cfs: any) => {
  const distinctResponses: any[] = []; // as we may already have duplicates from before
  question.Responses.forEach((resp: any) => {
    cfs.forEach((cf: any) => {
      if (cf.CustomFieldID === resp.CustomFieldID) {
        distinctResponses.push(resp);
      }
    });
  });
  const parentQuestion: any[] = [];
  questions.forEach((q: any, index: number) => {
    if (
      q.QuestionIsMulti === 1 &&
      q.QuestionOrder < question.QuestionOrder &&
      question.QuestionText.includes(q.QuestionText)
    ) {
      parentQuestion.push(q);
    }
  });
  return parentQuestion;
};

const addResponseSet = (question: any, cfields: any, questions: any) => {
  // this is for custom questions to have multiple responses
  // first we find the custom fields accociated with this question in their order
  // after we  create an array of responces to attach to a new question with numeric ids
  const distinctResponses: any[] = []; // as we may already have duplicates from before
  question.Responses.forEach((resp: any) => {
    cfields.forEach((cf: any) => {
      if (
        cf.CustomFieldID === resp.CustomFieldID &&
        cf.InstanceQuestionElementID == resp.QuestionElementID
      ) {
        distinctResponses.push(resp);
      }
    });
  });

  const newResponses: any[] = [];
  const currentResponses = newResponses.length - 1;
  distinctResponses.forEach((resp: any, index: number) => {
    newResponses.push({
      DuplicatedQuestionElementID: resp.QuestionElementID,
      QuestionElementID: currentResponses + 1 + index,
      QuestionElementText: resp.QuestionElementText,
      QuestionElementOrder: resp.QuestionElementOrder,
      QuestionElementResponseBIT: resp.QuestionElementResponseBIT,
      QuestionElementResponseVARCHAR: null,
      QuestionElementResponseDATETIME: null,
      QuestionElementResponseINT: null,
      QuestionElementResponseLONGVARCHAR: null,
      QuestionElementResponseURL: null,
      ListValueID:
        getCustomFieldType(getCfTypeID(resp.CustomFieldID, cfields)) ===
        "Multi-select List"
          ? []
          : null,
      QuestionID: resp.QuestionID,
      ObjectStatusID: resp.ObjectStatusID,
      RiskMatrixDimensionTypeID: null,
      RiskMatrixElementID: null,
      CustomFieldID: resp.CustomFieldID,
      DocumentID: [],
    });
  });

  const quest = questions.filter(
    (q: any) =>
      (q.ActionQuestionID === question.ActionQuestionID &&
        q.QuestionText.includes(question.QuestionText)) ||
      (isNumeric(q.InstanceQuestionID) &&
        q.QuestionText.includes(question.QuestionText)),
  );
  const newid: any = isNumeric(quest[quest.length - 1].InstanceQuestionID)
    ? quest[quest.length - 1].InstanceQuestionID + 1
    : quest.length;
  const order: any = quest[quest.length - 1].QuestionOrder + 1;

  const newQuestion: any = {
    InstanceQuestionID: newid,
    InstanceID: question.InstanceID,
    ActionQuestionID: question.ActionQuestionID,
    QuestionText: question.QuestionText,
    QuestionOrder: order,
    QuestionIsUber: question.QuestionIsUber,
    QuestionMandatory: question.QuestionMandatory,
    QuestionTypeID: question.QuestionTypeID,
    QuestionIsMulti: 0,
    ParentQuestionElementID: question.ParentQuestionElementID,
    ListID: question.ListID,
    RiskMatrixID: question.RiskMatrixID,
    Responses: newResponses,
    ObjectStatusID: question.ObjectStatusID,
    QuestionRespondentUserName: question.QuestionRespondentUserName,
    QuestionRespondentUserID: question.QuestionRespondentUserID,
    QuestionResponseTs: question.QuestionResponseTs,
  };

  const newQuestions: any[] = [];
  let previousIndex = 0;
  let entered = false;
  let parentIDchecked = false;
  let orderNew = 0;
  questions.forEach((q: any, index: number) => {
    if (q.InstanceQuestionID === question.InstanceQuestionID) {
      newQuestions.push({ ...q, QuestionOrder: orderNew });
      parentIDchecked = true;
      if (newid === 1) {
        orderNew += 1;
        newQuestions.push({ ...newQuestion, QuestionOrder: orderNew });
        entered = true;
      } else {
        previousIndex = index;
      }
    } else if (
      (questions[previousIndex].InstanceQuestionID === newid - 1 ||
        orderNew === newQuestion.QuestionOrder) &&
      newQuestion.QuestionText.includes(question.QuestionText) &&
      entered === false &&
      parentIDchecked
    ) {
      newQuestions.push({ ...newQuestion, QuestionOrder: orderNew });
      orderNew += 1;
      newQuestions.push({ ...q, QuestionOrder: orderNew });
      entered = true;
    } else {
      newQuestions.push({ ...q, QuestionOrder: orderNew });
      previousIndex = index;
    }
    orderNew += 1;
  });
  if (entered === false) {
    newQuestions.push({ ...newQuestion, QuestionOrder: newQuestions.length });
  }

  return newQuestions;
};

const hasAMultiChild = (question: any, questions: any) => {
  const filteredQuestions: any[] = [];
  questions = questions.filter(
    (q: any) => q.InstanceQuestionID !== question.InstanceQuestionID,
  );
  questions.forEach((q: any) => {
    if (
      q.ActionQuestionID === question.ActionQuestionID &&
      q.QuestionText.includes(question.QuestionText)
    ) {
      filteredQuestions.push(q);
    } else if (
      isNumeric(q.InstanceQuestionID) &&
      q.QuestionText.includes(question.QuestionText)
    ) {
      filteredQuestions.push(q);
    }
  });
  return filteredQuestions.length > 0;
};
const isLastChildOfMulti = (
  question: any,
  questions: any,
  cfs: any,
): boolean => {
  // check if it is a child or a custom with isMulti value set to false. If the later exit immediatly
  if (!isNumeric(question.InstanceQuestionID)) {
    return false;
  }
  const distinctResponses: any[] = []; // as we may already have duplicates from before
  question.Responses.forEach((resp: any) => {
    cfs.forEach((cf: any) => {
      if (
        cf.CustomFieldID === resp.CustomFieldID &&
        (isNumeric(resp.QuestionElementID)
          ? cf.InstanceQuestionElementID === resp.DuplicatedQuestionElementID
          : cf.InstanceQuestionElementID === resp.QuestionElementID)
      ) {
        distinctResponses.push(resp);
      }
    });
  });
  let check: any;
  questions.forEach((q: any, index: number) => {
    if (
      q.InstanceQuestionID === question.InstanceQuestionID &&
      q.QuestionOrder === question.QuestionOrder &&
      q.QuestionTypeID === QuestionType.Custom
    ) {
      if (index - 1 >= 0) {
        check = true;
        for (
          let i = 0;
          i < distinctResponses.length &&
          i < questions[index - 1].Responses.length;
          i++
        ) {
          if (
            questions[index - 1].Responses[i].CustomFieldID !==
            distinctResponses[i].CustomFieldID
          ) {
            check = false;
          }
        }
        if (
          index + 1 <= questions.length - 1 &&
          check === true &&
          questions[index + 1].QuestionTypeID === QuestionType.Custom
        ) {
          for (let i = 0; i < distinctResponses.length; i++) {
            if (
              questions[index + 1].Responses[i] &&
              questions[index + 1].Responses[i].CustomFieldID ===
                distinctResponses[i].CustomFieldID &&
              questions[index + 1].ActionQuestionID ===
                question.ActionQuestionID
            ) {
              check = false;
            }
          }
        }
      } else {
        check = false;
      }
    }
  });
  if (check !== undefined) {
    return check;
  }
  return false;
};

const QuestionCreatedFromMulti = (question: any, questions: any) => {
  let check = false;
  questions.forEach((quest: any) => {
    if (
      quest.QuestionIsMulti &&
      question.QuestionText.includes(`${quest.QuestionText}`) &&
      question.QuestionIsMulti === 0 &&
      question.InstanceQuestionID !== quest.InstanceQuestionID
    ) {
      check = true;
    }
  });
  return check;
};

const questionIsVisible = (
  thisQuestion: any,
  index: number,
  allQuestions: any[],
) => {
  if (!thisQuestion.ParentQuestionElementID) {
    return true;
  }
  // only show question if all parent/ancestor questions in conditional 'chain' have had parent element answered
  let visible = false;
  let nextParentElementID = thisQuestion.ParentQuestionElementID;
  while (nextParentElementID) {
    const parentQuestion = allQuestions.find((question: any) =>
      question.Responses.find(
        (response: any) =>
          response.QuestionElementID === nextParentElementID &&
          response.QuestionElementResponseBIT === 1,
      ),
    );
    if (parentQuestion) {
      visible = true;
      nextParentElementID = parentQuestion.ParentQuestionElementID;
    } else {
      visible = false;
      nextParentElementID = null;
    }
  }
  return visible;
};

const getMaxDate = (date: any, timezone: string) => {
  const offsetDifference =
    moment(date).utcOffset() - moment(date).tz(timezone).utcOffset();
  const adjustedDate = moment
    .utc(date)
    .subtract(1, "seconds")
    .subtract(offsetDifference, "minutes")
    .subtract(1, "days");
  return adjustedDate.toDate();
};

const isUserDeleted = (userIDToCheck: string, allUsers: any[]) => {
  const user: any = allUsers.find((user: any) => user.UserID === userIDToCheck);
  if (user) return `${user.UserFirstName} ${user.UserLastName}`;
  return "Deleted User";
};
const validateResponses = (questions: any[]): boolean => {
  for (const question of questions) {
    const isVisible = questionIsVisible(question, 0, questions);
    // SectionBreak questions are not mandatory even if they lie and say they are - dont trust them
    const isMandatory =
      question.QuestionTypeID === QuestionType.SectionBreak
        ? false
        : question.QuestionMandatory;
    if (isVisible && isMandatory) {
      // Question should be checked for response
      if (
        question.QuestionTypeID === QuestionType.InherentRisk ||
        question.QuestionTypeID === QuestionType.ResidualRisk
          ? !question.Responses.every(
              (response: any) => response.RiskMatrixElementID !== null,
            )
          : !question.Responses.find(
              (response: any) =>
                response.QuestionElementResponseBIT ||
                response.QuestionElementResponseVARCHAR !== null ||
                response.QuestionElementResponseLONGVARCHAR !== null ||
                response.QuestionElementResponseDATETIME !== null ||
                response.ListValueID !== null ||
                response.DocumentID.length > 0 ||
                response.QuestionElementResponseINT !== null ||
                response.QuestionElementResponseURL !== null,
            )
      ) {
        return false;
      }
    }
  }
  return true;
};

const validateCustomResponses = (CustomFieldsForQuestion: any[]): boolean => {
  for (const cf of CustomFieldsForQuestion) {
    if (
      cf.CustomFieldTypeColumnName === "URL" &&
      cf.Value !== null &&
      cf.Value !== "" &&
      cf.Value !== "http://" &&
      cf.Value !== "https://"
    ) {
      if (!urlRegex.test(cf.Value)) {
        return false;
      }
    }
  }
  return true;
};

const checkResponse = (questions: any[], cfs: any[]) => {
  let check = false;
  questions.forEach((quest: any) => {
    quest.Responses.forEach((response: any) => {
      if (
        response.QuestionElementResponseBIT === 1 ||
        (getCustomFieldType(getCfTypeID(response.CustomFieldID, cfs)) ===
          "Multi-select List" &&
          response.ListValueID.length !== 0) ||
        response.QuestionElementResponseVARCHAR !== null ||
        response.RiskMatrixElementID !== null ||
        response.QuestionElementResponseDATETIME !== null ||
        (getCustomFieldType(getCfTypeID(response.CustomFieldID, cfs)) ===
          "Single Select List" &&
          response.ListValueID !== null) ||
        response.DocumentID.length > 0 ||
        response.QuestionElementResponseINT !== null ||
        response.QuestionElementResponseURL !== null
      ) {
        check = true;
      }
    });
  });
  return check;
};

const ScreensInstance = () => {
  const { displayToast } = useToast();
  const { get, post, put } = useLegacyApi();
  const [pageStatus, setPageStatus] =
    useState<ScreensInstancePageStatus>("Loading");
  const [data, setData] = useState<ScreensInstanceState>(null);
  const [display, setDisplay] = useState<string>("");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const urlParams = useParams();
  const appState = useAppState();
  const navigate = useNavigate();
  const parentIdFromUrl = searchParams.get("parentid") || "";

  useEffect(() => {
    const fetchInstance = async () => {
      setPageStatus("Loading");
      const search = display
        ? location.search.substring(0, location.search.indexOf("?", 1))
        : location.search;
      const result = await get(`instance/${urlParams.instanceID}${search}`);
      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "error",
          title: "Failed to retrieve Instance",
        });
      } else {
        if (result.data.statusCode !== 200) {
          // display error
          setPageStatus("Error");
        } else if (result.data.ParentID) {
          // proceed
          result.data.ActionID =
            result.data.ActionID ||
            getUrlSearchParam(location.search, "parentid");
          const parent = result.data.Requirements.find((requirement: any) =>
            requirement.ActionIDs.includes(result.data.ActionID),
          );
          result.data.ParentObjectTypeGroup = parent
            ? parent.ObjectTypeGroupName
            : "Requirement";
          const taskWindow = getTaskWindow({
            instanceWhen:
              result.data.InstanceWhenTs &&
              result.data.InstanceWhenTs.length >= 1
                ? new Date(result.data.InstanceWhenTs)
                : new Date(),
            windowBefore: result.data.InstanceWindowBefore,
            windowAfter: result.data.InstanceWindowAfter,
          });
          result.data.taskWindow = taskWindow;
          setData(result.data);
          setPageStatus(urlParams.instanceID === "new" ? "New" : "Ready");
        } else {
          const taskWindow = getTaskWindow({
            instanceWhen:
              result.data.InstanceWhenTs &&
              result.data.InstanceWhenTs.length >= 1
                ? new Date(result.data.InstanceWhenTs)
                : new Date(),
            windowBefore: result.data.InstanceWindowBefore,
            windowAfter: result.data.InstanceWindowAfter,
          });
          result.data.taskWindow = taskWindow;
          result.data.ParentObjectTypeGroup = Access.objectTypeGroupIDToName(
            result.data.ObjectTypeGroupID,
          );
          setData(result.data);
          setPageStatus(urlParams.instanceID === "new" ? "New" : "Ready");
        }
        setDisplay(
          result.data.ParentObjectTypeGroup &&
            result.data.ParentObjectTypeGroup !== "Requirement"
            ? `?display=${result.data.ParentObjectTypeGroup.toLowerCase()}s`
            : "?display=requirements",
        );
      }
    };

    if (appState.auth.isLoggedIn) {
      fetchInstance();
    }
  }, [urlParams]); // eslint-disable-line

  // Title and crumbs
  const pageTitle =
    pageStatus === "New"
      ? "New instance"
      : data && pageStatus !== "Loading"
      ? pageStatus === "Submitting"
        ? data.taskWindow.due.displayDate
        : data.taskWindow.due.displayDate
      : "";

  const breadcrumbs =
    data && pageStatus !== "Loading"
      ? getBreadcrumbs(
          pageStatus === "New"
            ? "New Task"
            : pageStatus === "Submitting"
            ? data.taskWindow.due.displayDate
            : data.taskWindow.due.displayDate,
          data.ParentID,
          data.Requirements,
          {
            label: data.ActionName,
            link: `/action/${data.ActionID}${display}`,
            isActive: data.ActionIsActive,
          },
          getUrlSearchParam(location.search, "display") === "actions"
            ? { label: "Action Dashboard", link: "/action?display=actions" }
            : {
                label: `${data.ParentObjectTypeGroup} Dashboard`,
                link: `/${data.ParentObjectTypeGroup.toLowerCase()}?display=${data.ParentObjectTypeGroup.toLowerCase()}s`,
              },
          getUrlSearchParam(location.search, "display") === "actions"
            ? "actions"
            : display,
        )
      : [];

  const pageSubtitle =
    data && pageStatus !== "Loading" ? data.ActionReadableID : "";

  return (
    <LegacyScreenContainer
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      pageSubtitle={pageSubtitle}>
      {data && pageStatus !== "Loading" ? (
        <>
          {/* <Header
              breadcrumbs={}
              title={
                pageStatus === "New"
                  ? "New instance"
                  : pageStatus === "Submitting"
                  ? moment
                      .utc(data.InstanceWhenTs)
                      .local()
                      .format("DD-MMM-YYYY")
                  : formatDateTime({
                      date: data.InstanceWhenTs,
                      format: "Date",
                    })
              }
              timestamp={
                pageStatus === "New"
                  ? ""
                  : formatDateTime({
                      date: data.InstanceWhenTs,
                      format: "Time",
                    })
              }
              subtitle={data.ActionReadableID}
              buttonProps={{}}
            /> */}
          <Card
            title="Details"
            collapsable
            headerColsRight
            headerCols={
              pageStatus !== "New"
                ? [
                    {
                      colProps: { sm: "auto" },
                      children: (
                        <Col sm="auto">
                          <DocumentGenerator
                            data={{
                              ...data,
                              ObjectTypeGroupName: "Instance",
                            }}
                          />
                        </Col>
                      ),
                    },
                  ]
                : []
            }>
            <Formik
              enableReinitialize
              initialValues={data}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                values.InstanceWindowBefore =
                  Math.ceil(
                    moment
                      .utc(values.InstanceWhenTs)
                      .local()
                      .diff(moment(), "days", true),
                  ) + 1;
                setData(values);
                setPageStatus("Submitting");

                try {
                  const results = await put("instance", {
                    ...values,
                    InstanceWhenTs: validateDate(
                      values.InstanceWhenTs,
                      values.ActionTimezone,
                    ),
                    parentID: getUrlSearchParam(location.search, "parentid"),
                  });

                  displayToast({
                    status: "success",
                    title: `${values.ActionTypeName} created successfully`,
                  });
                  navigate(
                    {
                      pathname: `/instance/${results.data[0].InstanceID}`,
                      search: display,
                    },
                    {
                      state: {
                        hasChanged: true,
                        display: display
                          ? getUrlSearchParam(location.search, "display")
                          : "",
                      },
                    },
                  );
                } catch (err) {
                  displayToast({
                    status: "error",
                    title: `Failed to create ${values.ActionTypeName}`,
                  });
                  setPageStatus("New");
                }
                actions.setSubmitting(false);
              }}
              render={(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Action Type
                    </Form.Label>
                    <Col sm="9">
                      <Text
                        value={formikProps.values.ActionTypeName}
                        name="ActionName"
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Parent
                    </Form.Label>
                    <Col sm="9">
                      <MultiSelect
                        onUpdate={(e: any) => {}}
                        name="ParentID"
                        value={
                          pageStatus !== "New"
                            ? formikProps.values.ParentIDs
                            : formikProps.values.ParentIDs !== undefined
                            ? formikProps.values.ParentIDs
                            : []
                        }
                        optionsList={formikProps.values.ParentIDs}
                        optionsChild
                        readOnly
                        readOnlyComponent={formikProps.values.ParentIDs.sort(
                          (a, b) => a.ParentTree.length - b.ParentTree.length,
                        )}
                        disabled={data.ObjectStatusID === ObjectStatus.Closed}
                        seeMore
                        parentIdFromUrl={parentIdFromUrl}
                        display={display}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Person Responsible
                    </Form.Label>
                    <Col sm="9">
                      <Text
                        value={
                          formikProps.values.InstanceResponsibleUsers
                            ? formikProps.values.InstanceResponsibleUsers.map(
                                (user: any) =>
                                  isUserDeleted(
                                    user.InstanceResponsibleUserID,
                                    formikProps.values.ResponsibleUsers,
                                  ),
                              ).join(", ")
                            : ""
                        }
                        name="InstanceResponsibleUserName"
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Due
                    </Form.Label>
                    {pageStatus === "New" || pageStatus === "Submitting" ? (
                      <>
                        <Col sm="auto">
                          <DateField
                            name="InstanceWhenTs"
                            selected={formikProps.values.InstanceWhenTs}
                            onUpdate={((name: any, data: any) =>
                              formikProps.setFieldValue(name, data)).bind(
                              {},
                              "InstanceWhenTs",
                            )}
                            readOnly={pageStatus !== "New"}
                            min={moment().toDate()}
                            max={
                              formikProps.values.NextInstanceOccurrence
                                ? getMaxDate(
                                    formikProps.values.NextInstanceOccurrence,
                                    formikProps.values.ActionTimezone,
                                  )
                                : undefined
                            }
                            authState={appState.auth}
                            allDates={false}
                          />
                        </Col>
                        <Col sm="auto">
                          {pageStatus === "New" ? (
                            <span className="form-label-small">
                              (must be before the next instance is due
                              {formikProps.values.ActionTimezone !==
                              moment.tz.guess()
                                ? ` - will be converted to ${formikProps.values.ActionTimezone} time`
                                : ""}
                              )
                            </span>
                          ) : (
                            ""
                          )}
                        </Col>
                      </>
                    ) : (
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          value={`${formikProps.values.taskWindow.due.displayDate} ${formikProps.values.taskWindow.due.displayTime}`}
                          readOnly
                          plaintext
                        />
                        <Text
                          value={`(available ${formikProps.values.taskWindow.open.displayDate} ${formikProps.values.taskWindow.open.displayTime} to 
                            ${formikProps.values.taskWindow.close.displayDate} ${formikProps.values.taskWindow.close.displayTime}, local time)`}
                          name="DueBetween"
                          readOnly
                        />
                      </Col>
                    )}
                  </Form.Group>
                  <CustomFieldList
                    customFields={formikProps.values.CustomFields}
                    pageStatus="Ready"
                  />
                  <History
                    ID={data.InstanceID}
                    authState={appState.auth}
                    lastModifiedTs={data.ModifiedTs}
                    lastModififedUser={data.ModifiedUserName}
                  />
                  {pageStatus === "New" && (
                    <Form.Group as={Row}>
                      <Col sm={{ span: "auto", offset: "2" }}>
                        <Button
                          type="submit"
                          onClick={() => {
                            if (
                              JSON.stringify(formikProps.errors) !== "{}" &&
                              formikProps.isSubmitting === false
                            ) {
                              const errors = JSON.stringify(formikProps.errors)
                                .replace("{", "")
                                .replace("}", "")
                                .split(",");
                              errors.map(
                                (e: string, index: number) =>
                                  (errors[index] = e.substring(
                                    e.lastIndexOf(":") + 1,
                                  )),
                              );
                              const message = `Failed to create ${
                                formikProps.values.ActionTypeName
                              }\n\n${errors.join("\n")}`;
                              displayToast({
                                status: "error",
                                title: message,
                              });
                            }
                          }}>
                          Submit
                        </Button>
                      </Col>
                      <Col sm="auto">
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => {
                            if (data.ActionID) {
                              navigate(`/action/${data.ActionID}${display}`);
                            } else {
                              navigate("");
                            }
                          }}>
                          Cancel
                        </Button>
                      </Col>
                    </Form.Group>
                  )}
                </Form>
              )}
            />
          </Card>

          {pageStatus === "Ready" ? (
            <TemplateDocumentGenerator data={data} authState={appState.auth} />
          ) : null}

          {pageStatus !== "New" &&
            pageStatus !== "Submitting" &&
            data.InstanceResponses.map((response: any) => (
              <InstanceResponse
                key={response.InstanceID}
                {...response}
                ActionID={data.ActionID}
                InstanceWindowBefore={data.InstanceWindowBefore}
                InstanceWindowAfter={data.InstanceWindowAfter}
                InstanceWhenTs={data.InstanceWhenTs}
                taskWindow={data.taskWindow}
                DraftResponse={
                  response.InstanceMinViableResponse === 0 &&
                  checkResponse(
                    response.Questions,
                    data.CustomFieldsForQuestion,
                  )
                }
                CustomFieldsForQuestion={data.CustomFieldsForQuestion}
                ControlList={getControlList(data.ParentID, data.Requirements)}
                initialCollapsedState={data.InstanceResponses.length > 1}
                InstanceResponsibleUsers={data.InstanceResponsibleUsers}
                ObjectTypeID={data.ObjectTypeID}
                displayToast={displayToast}
                ResponsibleUsers={data.ResponsibleUsers}
              />
            ))}
        </>
      ) : pageStatus !== "Error" ? (
        <div className="progress-spinner" style={{ marginTop: "20%" }}>
          <Loading size={"xl"} />
        </div>
      ) : (
        <Card2 style={{ marginTop: "20%", textAlign: "center" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <Frown />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>
                The record you are trying to access is not available - either
                you don’t have permission to see it, it’s in another account
                (use the account switcher to move to the other account then try
                to link again), or the record doesn’t exist
              </h1>
            </Col>
          </Row>
        </Card2>
      )}
    </LegacyScreenContainer>
  );
};

interface InstanceResponseProps {
  ActionID: string;
  InstanceID: string;
  InstanceResponsibleUserID: string;
  InstanceResponsibleUserName: string;
  InstanceResponsibleUsers: any[];
  InstanceMinViableResponse: number;
  DraftResponse: boolean;
  InstanceWindowBefore: number;
  InstanceWindowAfter: number;
  InstanceWhenTs: number;
  ObjectStatusID: string;
  ObjectStatusName: string;
  ObjectStatusDescription: string;
  ObjectTypeID: string;
  RiskMatrix: any;
  Questions: any;
  CustomFieldsForQuestion: any;
  ControlList: any[];
  ResponsibleUsers: User[];
  UserIDsToAlert?: string[];
  Deleting?: boolean;
  initialCollapsedState: boolean;
  displayToast: DisplayToastFn;
  taskWindow: TaskWindow;
}

type ScreensInstanceResponseState = InstanceResponseProps | null;

const InstanceResponse = (props: InstanceResponseProps) => {
  const [savedData, setSavedData] = useState(null);
  const [originalData, setOriginalData] =
    useState<ScreensInstanceResponseState>(null);
  const [pageStatus, setPageStatus] =
    useState<ScreensInstancePageStatus>("Loading");
  const [data, setData] = useState<ScreensInstanceResponseState>(props);
  const appState = useAppState();
  const location = useLocation();
  const { displayToast } = props;

  const { post } = useLegacyApi();

  const respondedTo = !!data?.InstanceMinViableResponse;

  useEffect(() => {
    const fetchSavedData = () => {
      const storedValues = window.localStorage.getItem(`${props.InstanceID}`);
      const parsedStoredValues = storedValues ? JSON.parse(storedValues) : "";
      if (
        data &&
        parsedStoredValues &&
        checkStoredValues(parsedStoredValues, data, "Instance") &&
        parsedStoredValues.InstanceID === data.InstanceID
      ) {
        setSavedData({ ...data, ...parsedStoredValues });
      } else {
        setSavedData(null);
      }
    };

    if (data && appState.auth.isLoggedIn && pageStatus === "Loading") {
      fetchSavedData();
      if (
        location.state &&
        location.state.pageStatus &&
        location.state.pageStatus === "Responding"
      ) {
        clearLocationState("pageStatus");
        setPageStatus("Responding");
      } else {
        setPageStatus("Ready");
      }
    }
  }, [appState.auth.isLoggedIn, data]);

  return pageStatus !== "Loading" && data ? (
    <Card
      key={data.InstanceID}
      title={`${data.InstanceResponsibleUserName} Response ${
        data.DraftResponse ? "(Draft)" : ""
      }`}
      collapsable
      initialCollapsedState={data.initialCollapsedState}
      headerComponent={(
        cardProps: any, // eslint-disable-line react/no-unstable-nested-components
      ) => (
        <>
          <Col>
            <Status
              variant={objectStatusToVariant(data.ObjectStatusID)}
              text={data.ObjectStatusName}
              tooltip={data.ObjectStatusDescription}
            />
            {data.DraftResponse ? (
              <Status
                variant={objectStatusToVariant("Draft")}
                text="Draft"
                style={{ color: "white", marginLeft: "10px" }}
                tooltip="This Response is a Draft"
              />
            ) : null}
          </Col>

          {pageStatus !== "Responding" ? (
            <Col sm="auto">
              {!isTaskAvailable(data.taskWindow).isAvailable ? (
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-cannot-respond">
                      {isTaskAvailable(data.taskWindow).isEarly
                        ? `Not available for response before ${data.taskWindow.open.displayDate}`
                        : `Not available for response after ${data.taskWindow.close.displayDate}`}
                    </Tooltip>
                  }>
                  <Button variant="primary" className="disabled">
                    Respond Now
                  </Button>
                </OverlayTrigger>
              ) : Access.checkAccess(
                  appState.app.permissions_LEGACY,
                  Permissions.CodeAction,
                  Permissions.TypeUpdate,
                  false,
                  data.ObjectTypeID,
                ) ||
                data.InstanceResponsibleUserID ===
                  appState.app.attributes?.userID ? (
                savedData ? (
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-unsaved-changes">
                        You have unsaved changes
                      </Tooltip>
                    }>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setOriginalData(data);
                        setData(savedData);
                        setTimeout(() => setPageStatus("Responding"), 20);
                        cardProps.updateCollapsed(false);
                      }}>
                      Respond Now
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setTimeout(() => setPageStatus("Responding"), 20);
                      cardProps.updateCollapsed(false);
                    }}>
                    {respondedTo ? "Edit Response" : "Respond Now"}
                  </Button>
                )
              ) : (
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id="tooltip-cannot-respond">
                      You do not have permission to respond
                    </Tooltip>
                  }>
                  <Button variant="primary" className="disabled">
                    Respond Now
                  </Button>
                </OverlayTrigger>
              )}
            </Col>
          ) : null}
        </>
      )}>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={async (values, actions) => {
          if (
            ((!validateResponses(values.Questions) ||
              !validateCustomResponses(values.CustomFieldsForQuestion)) &&
              !values.DraftResponse) ||
            values.Deleting === true
          ) {
            actions.setSubmitting(false);
            setPageStatus("Responding");
          } else {
            setPageStatus("Submitting");

            try {
              values.UserIDsToAlert = [];
              values.Questions = values.Questions.map((question: any) => {
                if (
                  question.QuestionTypeID === QuestionType.InherentRisk ||
                  question.QuestionTypeID === QuestionType.ResidualRisk
                ) {
                  question.RiskMatrixID = values.RiskMatrix.RiskMatrixID;
                } else if (
                  question.QuestionTypeID === QuestionType.AlertRightNow &&
                  !values.DraftResponse
                ) {
                  const thisQuestionFromDB = data.InstanceMinViableResponse
                    ? data.Questions.find(
                        (q: any) =>
                          q.QuestionID === question.QuestionID &&
                          q.Responses[0].QuestionElementResponseVARCHAR,
                      )
                    : undefined;
                  const usersAlreadyAlerted = thisQuestionFromDB
                    ? thisQuestionFromDB.Responses[0].QuestionElementResponseVARCHAR.split(
                        ",",
                      )
                    : [];
                  const newUsersToAlert = question.Responses[0]
                    .QuestionElementResponseVARCHAR
                    ? question.Responses[0].QuestionElementResponseVARCHAR.split(
                        ",",
                      )
                    : [];
                  values.UserIDsToAlert = values.UserIDsToAlert!.concat(
                    newUsersToAlert.filter(
                      (userID: string) => !usersAlreadyAlerted.includes(userID),
                    ),
                  );
                }
                return question;
              });

              values.CustomFieldsForQuestion =
                values.CustomFieldsForQuestion.map((f: any) => {
                  if (
                    f.CustomFieldTypeColumnName === "DocumentID" ||
                    f.CustomFieldTypeColumnName === "OneDriveDocumentID"
                  ) {
                    return { ...f, Value: [] };
                  }
                  return f;
                });

              values.Questions = values.Questions.map((question: any) => {
                question.Responses.map((field: any) => {
                  if (
                    field.QuestionElementResponseURL === "http://" ||
                    field.QuestionElementResponseURL === "https://"
                  ) {
                    field.QuestionElementResponseURL = "";
                  }
                  if (field.DocumentID.length > 0) {
                    field.DocumentID = field.DocumentID.map((doc: any) => {
                      if (doc.status === "done") {
                        return { ...doc, buffer: {} };
                      }
                      return doc;
                    });
                  }
                  return field;
                });
                return question;
              });

              setData(values);
              setSavedData(null);
              const results = await post(`instance/${values.InstanceID}`, {
                ...values,
              });

              if (values.DraftResponse) {
                displayToast({
                  status: "success",
                  title: `Draft Response saved`,
                });
              } else {
                displayToast({
                  status: "success",
                  title: `Response updated successfully`,
                });
              }
              // remove the local storage after submit
              window.localStorage.removeItem(`${values.InstanceID}`);
              window.location.reload();
            } catch (err) {
              displayToast({
                status: "error",
                title: "Failed to update Response",
              });
              setPageStatus("Responding");
            }
            actions.setSubmitting(false);
          }
        }}
        render={(formikProps) => {
          // here we save at every change in local storage
          if (pageStatus === "Responding") {
            LocalStorage.saveInLocalStorage(
              formikProps.values.InstanceID,
              formikProps,
            );
          }
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              {pageStatus === "Responding" &&
              data.InstanceResponsibleUserID &&
              data.InstanceResponsibleUserID !==
                appState.app.attributes?.userID ? (
                <p>
                  <Icon name="AlertTriangle" />
                  This task is for a different user, please ensure you intended
                  to answer it.
                </p>
              ) : (
                <></>
              )}
              {formikProps.values.Questions.filter(questionIsVisible).map(
                (question: any) => (
                  <Form.Group key={question.InstanceQuestionID}>
                    <Question
                      key={question.InstanceQuestionID}
                      {...question}
                      binIcon={
                        isNumeric(question.InstanceQuestionID) ||
                        (QuestionCreatedFromMulti(
                          question,
                          formikProps.values.Questions,
                        ) &&
                          pageStatus === "Responding")
                      }
                      CustomFields={formikProps.values.CustomFieldsForQuestion}
                      readOnly={pageStatus !== "Responding"}
                      pageStatus={pageStatus}
                      RiskMatrix={props.RiskMatrix ? props.RiskMatrix : null}
                      Controls={props.ControlList}
                      formikData={formikProps.values}
                      setFieldValue={formikProps.setFieldValue}
                      ResponsibleUsers={props.ResponsibleUsers}
                      InstanceMinViableResponse={
                        props.InstanceMinViableResponse
                      }
                      onUpdate={(event: any, a: any) => {
                        if (
                          event.value !== undefined &&
                          event.value === "remove_question"
                        ) {
                          const newQs = removeQuestion(
                            event.questionID,
                            event.questionOrder,
                            formikProps.values.Questions,
                          );
                          // had to add deleting as deleting an inbetween multi response triggers submit
                          formikProps.setValues({
                            ...formikProps.values,
                            Deleting: true,
                            Questions: newQs,
                          });
                        } else {
                          const newQuestions = formikProps.values.Questions.map(
                            (question: any) => {
                              const newQuestion = JSON.parse(
                                JSON.stringify(question),
                              );
                              if (
                                event.target !== undefined &&
                                event.target.name.split("_")[0] ===
                                  newQuestion.InstanceQuestionID
                              ) {
                                if (
                                  newQuestion.QuestionTypeID ===
                                  QuestionType.SingleSelect
                                ) {
                                  newQuestion.Responses =
                                    newQuestion.Responses.map(
                                      (response: any) => {
                                        if (
                                          event.target.id ===
                                          response.QuestionElementID
                                        ) {
                                          return {
                                            ...response,
                                            QuestionElementResponseBIT: event
                                              .target.checked
                                              ? 1
                                              : 0,
                                          };
                                        }
                                        if (
                                          event.target.checked &&
                                          response.QuestionElementResponseBIT ===
                                            1
                                        ) {
                                          return {
                                            ...response,
                                            QuestionElementResponseBIT: 0,
                                          };
                                        }
                                        return response;
                                      },
                                    );
                                } else if (
                                  newQuestion.QuestionTypeID ===
                                  QuestionType.Notes
                                ) {
                                  newQuestion.Responses =
                                    newQuestion.Responses.map(
                                      (response: any) => {
                                        if (
                                          event.target.id ===
                                          response.QuestionElementID
                                        ) {
                                          return {
                                            ...response,
                                            QuestionElementResponseVARCHAR:
                                              event.target.value,
                                            QuestionElementResponseBIT:
                                              response.QuestionElementResponseVARCHAR !==
                                              ""
                                                ? 1
                                                : 0,
                                          };
                                        }
                                        return response;
                                      },
                                    );
                                } else if (
                                  newQuestion.QuestionTypeID ===
                                    QuestionType.InherentRisk ||
                                  newQuestion.QuestionTypeID ===
                                    QuestionType.ResidualRisk
                                ) {
                                  newQuestion.Responses =
                                    newQuestion.Responses.map(
                                      (response: any) => {
                                        if (
                                          event.target.name.split("_")[1] ===
                                          response.RiskMatrixDimensionTypeID
                                        ) {
                                          return {
                                            ...response,
                                            RiskMatrixElementID: event.target
                                              .checked
                                              ? event.target.value
                                              : "",
                                          };
                                        }
                                        return response;
                                      },
                                    );
                                }
                              } else if (
                                isCFID(event, newQuestion.Responses, a) &&
                                newQuestion.QuestionTypeID ===
                                  QuestionType.Custom
                              ) {
                                newQuestion.Responses =
                                  newQuestion.Responses.map((response: any) => {
                                    if (
                                      event.target !== undefined &&
                                      event.target.name ===
                                        response.CustomFieldID &&
                                      event.target.questionID ===
                                        newQuestion.InstanceQuestionID &&
                                      event.target.questionOrder ===
                                        newQuestion.QuestionOrder
                                    ) {
                                      const columnName = getColName(
                                        event.target.name,
                                        formikProps.values
                                          .CustomFieldsForQuestion,
                                      );
                                      if (
                                        columnName === "ListValueID" &&
                                        getCustomFieldType(
                                          getCfTypeID(
                                            event.target.name,
                                            formikProps.values
                                              .CustomFieldsForQuestion,
                                          ),
                                        ) === "Multi-select List"
                                      ) {
                                        return {
                                          ...response,
                                          ListValueID: event.target.value,
                                        };
                                      }
                                      if (
                                        columnName === "ListValueID" &&
                                        getCustomFieldType(
                                          getCfTypeID(
                                            event.target.name,
                                            formikProps.values
                                              .CustomFieldsForQuestion,
                                          ),
                                        ) === "Single Select List"
                                      ) {
                                        return {
                                          ...response,
                                          ListValueID: event.target.value,
                                        };
                                      }
                                      if (columnName === "VARCHAR") {
                                        return {
                                          ...response,
                                          QuestionElementResponseVARCHAR:
                                            event.target.value,
                                        };
                                      }
                                      if (columnName === "DATETIME") {
                                        return {
                                          ...response,
                                          QuestionElementResponseDATETIME:
                                            event.target.value,
                                        };
                                      }
                                      if (columnName === "INT") {
                                        return {
                                          ...response,
                                          QuestionElementResponseINT:
                                            event.target.value,
                                        };
                                      }
                                      if (columnName === "LONGVARCHAR") {
                                        return {
                                          ...response,
                                          QuestionElementResponseLONGVARCHAR:
                                            event.target.value,
                                        };
                                      }
                                      if (columnName === "URL") {
                                        return {
                                          ...response,
                                          QuestionElementResponseURL:
                                            event.target.value,
                                        };
                                      }
                                    } else if (
                                      event.cfID === response.CustomFieldID &&
                                      event.questionOrder ===
                                        newQuestion.QuestionOrder &&
                                      event.questionID ===
                                        newQuestion.InstanceQuestionID &&
                                      a.fileList
                                    ) {
                                      return {
                                        ...response,
                                        DocumentID: a.fileList,
                                      };
                                    } else if (
                                      event.cfID === response.CustomFieldID &&
                                      event.questionID ===
                                        newQuestion.InstanceQuestionID &&
                                      a !== null &&
                                      event.questionOrder ===
                                        newQuestion.QuestionOrder
                                    ) {
                                      const columnName = getColName(
                                        event.cfID,
                                        formikProps.values
                                          .CustomFieldsForQuestion,
                                      );
                                      if (columnName === "LONGVARCHAR") {
                                        return {
                                          ...response,
                                          QuestionElementResponseLONGVARCHAR: a,
                                        };
                                      }
                                    }
                                    return response;
                                  });
                              } else if (
                                event === newQuestion.InstanceQuestionID &&
                                newQuestion.QuestionTypeID ===
                                  QuestionType.Notes
                              ) {
                                newQuestion.Responses =
                                  newQuestion.Responses.map(
                                    (response: any) => ({
                                      ...response,
                                      QuestionElementResponseVARCHAR: a,
                                      QuestionElementResponseBIT:
                                        response.QuestionElementResponseVARCHAR !==
                                        ""
                                          ? 1
                                          : 0,
                                    }),
                                  );
                              } else if (
                                a &&
                                a.name === newQuestion.InstanceQuestionID &&
                                newQuestion.QuestionTypeID ===
                                  QuestionType.AlertRightNow
                              ) {
                                newQuestion.Responses[0].QuestionElementResponseVARCHAR =
                                  event
                                    ? event
                                        .map((opt: any) => `${opt.value}`)
                                        .join(",")
                                    : "";
                              }
                              return newQuestion;
                            },
                          );
                          formikProps.setValues({
                            ...formikProps.values,
                            Questions: newQuestions,
                          });
                        }
                      }}
                    />
                    {question.QuestionIsMulti === 1 &&
                    pageStatus === "Responding" &&
                    hasAMultiChild(question, formikProps.values.Questions) ===
                      false ? (
                      <Form.Group>
                        <Row>
                          <Col sm="2" />
                          <Col sm="8">
                            <div style={{ textAlign: "right" }}>
                              <Button
                                variant="dark"
                                id="AddCustomResponse"
                                title="Add another response"
                                onClick={() => {
                                  const newQuestions = addResponseSet(
                                    question,
                                    formikProps.values.CustomFieldsForQuestion,
                                    formikProps.values.Questions,
                                  );
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    Questions: newQuestions,
                                  });
                                }}>
                                <Plus className="feather" /> Add another
                                response{" "}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    ) : pageStatus === "Responding" &&
                      isLastChildOfMulti(
                        question,
                        formikProps.values.Questions,
                        formikProps.values.CustomFieldsForQuestion,
                      ) ? (
                      <Form.Group>
                        <Row>
                          <Col sm="2" />
                          <Col sm="8">
                            <div style={{ textAlign: "right" }}>
                              <Button
                                variant="dark"
                                id="AddCustomResponse"
                                title="Add another response"
                                onClick={() => {
                                  const parent = getParentMulti(
                                    question,
                                    formikProps.values.Questions,
                                    formikProps.values.CustomFieldsForQuestion,
                                  );
                                  const newQuestions = addResponseSet(
                                    parent[0],
                                    formikProps.values.CustomFieldsForQuestion,
                                    formikProps.values.Questions,
                                  );
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    Questions: newQuestions,
                                  });
                                }}>
                                <Plus className="feather" /> Add another
                                response{" "}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    ) : null}
                  </Form.Group>
                ),
              )}
              {pageStatus === "Responding" ? (
                <Row className="justify-content-sm-center">
                  <Col sm="auto">
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e: any) => {
                        if (!validateResponses(formikProps.values.Questions)) {
                          displayToast({
                            status: "error",
                            title: "Failed to update Response",
                            description:
                              "All questions marked as mandatory must be answered",
                          });
                          setPageStatus("Responding");
                          formikProps.setFieldValue(
                            "InstanceMinViableResponse",
                            0,
                          );
                          formikProps.setFieldValue("Deleting", true);
                        } else if (
                          !validateCustomResponses(
                            formikProps.values.CustomFieldsForQuestion,
                          )
                        ) {
                          displayToast({
                            status: "error",
                            title: "Failed to update Response",
                            description:
                              "All URL fields must contain valid URLs",
                          });
                          setPageStatus("Responding");
                          formikProps.setFieldValue(
                            "InstanceMinViableResponse",
                            0,
                          );
                        } else {
                          formikProps.setFieldValue(
                            `InstanceMinViableResponse`,
                            1,
                          );
                          formikProps.setFieldValue("Deleting", false);
                          formikProps.setFieldValue("DraftResponse", false);
                        }
                      }}>
                      Save and Complete
                    </Button>
                  </Col>
                  <Col sm="auto">
                    <Button
                      type="submit"
                      variant="dark"
                      onClick={() => {
                        formikProps.setFieldValue(
                          "InstanceMinViableResponse",
                          0,
                        );
                        formikProps.setFieldValue("Deleting", false);
                        formikProps.setFieldValue("DraftResponse", true);
                        setPageStatus("Responding");
                      }}>
                      Save as Draft
                    </Button>
                  </Col>
                  <Col sm="auto">
                    <Button
                      type="button"
                      variant="light"
                      onClick={() => {
                        // remove the local storage
                        window.localStorage.removeItem(
                          `${formikProps.values.InstanceID}`,
                        );
                        formikProps.resetForm();
                        if (savedData) {
                          setData(originalData);
                          setSavedData(null);
                        }
                        setPageStatus("Ready");
                      }}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Form>
          );
        }}
      />
    </Card>
  ) : (
    <>
      <div className="progress-spinner" style={{ marginTop: "20%" }}>
        <Loading size={"xl"} />
      </div>
    </>
  );
};

export { ScreensInstance };
